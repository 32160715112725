import { ComponentType, ComponentProps } from 'react';

import {
    CancellationReasonModal,
    DiscountModal,
    SuccessModal,
    WithLoaderModal,
    ConfirmationModal,
    NoConnectionModal,
    QuitWorkoutModal,
    CompletePersonalPlanModal,
    GoalModal,
    ProblemZonesModal,
    FitnessLevelModal,
    RestoreSubscriptionModal,
    RestoreSubscriptionErrorModal,
    CanNotLoginModal,
    ReminderModal,
    FreeMonthModal,
    RetryErrorModal,
    AdditionalContentOfferModal,
    PauseModal,
    ConfirmationModalWithDiscount,
    ReturnModal,
} from 'components/Modal';

export enum ModalName {
    DiscountModal = 'DiscountModal',
    CancellationReasonModal = 'CancellationReasonModal',
    ConfirmationModal = 'ConfirmationModal',
    WithLoaderModal = 'WithLoaderModal',
    SuccessModal = 'SuccessModal',
    NoConnectionModal = 'NoConnectionModal',
    QuitWorkoutModal = 'QuitWorkoutModal',
    CompletePersonalPlanModal = 'CompletePersonalPlanModal',
    GoalModal = 'GoalModal',
    ProblemZonesModal = 'ProblemZonesModal',
    FitnessLevelModal = 'FitnessLevelModal',
    RestoreSubscriptionModal = 'RestoreSubscriptionModal',
    RestoreSubscriptionErrorModal = 'RestoreSubscriptionErrorModal',
    CanNotLoginModal = 'CanNotLoginModal',
    ReminderModal = 'ReminderModal',
    FreeMonthModal = 'FreeMonthModal',
    RetryErrorModal = 'RetryErrorModal',
    AdditionalContentOfferModal = 'AdditionalContentOfferModal',
    PauseModal = 'PauseModal',
    ConfirmationModalWithDiscount = 'ConfirmationModalWithDiscount',
    ReturnModal = 'ReturnModal',
}

export type ModalPropsType = {
    [ModalName.DiscountModal]: ComponentProps<typeof DiscountModal>;
    [ModalName.CancellationReasonModal]: ComponentProps<typeof CancellationReasonModal>;
    [ModalName.ConfirmationModal]: ComponentProps<typeof ConfirmationModal>;
    [ModalName.WithLoaderModal]: ComponentProps<typeof WithLoaderModal>;
    [ModalName.SuccessModal]: ComponentProps<typeof SuccessModal>;
    [ModalName.NoConnectionModal]: ComponentType<typeof NoConnectionModal>;
    [ModalName.QuitWorkoutModal]: ComponentProps<typeof QuitWorkoutModal>;
    [ModalName.CompletePersonalPlanModal]: ComponentProps<typeof CompletePersonalPlanModal>;
    [ModalName.GoalModal]: ComponentProps<typeof GoalModal>;
    [ModalName.ProblemZonesModal]: ComponentProps<typeof ProblemZonesModal>;
    [ModalName.FitnessLevelModal]: ComponentProps<typeof FitnessLevelModal>;
    [ModalName.RestoreSubscriptionModal]: ComponentProps<typeof RestoreSubscriptionModal>;
    [ModalName.RestoreSubscriptionErrorModal]: ComponentProps<typeof RestoreSubscriptionErrorModal>;
    [ModalName.CanNotLoginModal]: ComponentProps<typeof CanNotLoginModal>;
    [ModalName.ReminderModal]: ComponentProps<typeof ReminderModal>;
    [ModalName.FreeMonthModal]: ComponentProps<typeof FreeMonthModal>;
    [ModalName.RetryErrorModal]: ComponentProps<typeof RetryErrorModal>;
    [ModalName.AdditionalContentOfferModal]: ComponentProps<typeof AdditionalContentOfferModal>;
    [ModalName.PauseModal]: ComponentProps<typeof PauseModal>;
    [ModalName.ConfirmationModalWithDiscount]: ComponentProps<typeof ConfirmationModalWithDiscount>;
    [ModalName.ReturnModal]: ComponentProps<typeof ReturnModal>;
};

export type ModalsMapType = {
    [key in ModalName]: ComponentType<ModalPropsType[key]>;
};
