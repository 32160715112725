import { createSelector } from 'reselect';

import { isMainSubscription } from 'helpers/subscriptions';

import { RootReducer } from 'types/store';

export const selectSubscriptions = (state: RootReducer) => state.subscriptions;

export const selectAllSubscriptions = createSelector(selectSubscriptions, ({ subscriptions }) => subscriptions);
export const selectDiscountSubscription = createSelector(
    selectSubscriptions,
    ({ discountSubscription }) => discountSubscription
);

export const selectCancellationCandidates = createSelector(
    selectSubscriptions,
    ({ cancellationCandidates }) => cancellationCandidates
);

export const selectMainSubscription = createSelector(selectAllSubscriptions, (subscriptions) =>
    subscriptions.find(({ product_code }) => isMainSubscription(product_code))
);

export const selectCancelReason = (state: RootReducer) => state.subscriptions.cancelReason;
