import { put, call } from 'redux-saga/effects';

import { IResultResponse } from 'api/types/response';
import api from 'api';

import { checkAllSubscriptionsWithDiscount } from 'store/subscriptions/helpers';
import { setDiscountSubscription, updateSubscription } from 'store/subscriptions/actions';
import { openModal } from 'store/modals/actions';

import { CANCEL_OFFER_SUCCESS_SCREEN_ID, DISCOUNT_APPLIED_ERROR, DISCOUNT_APPLIED_SUCCESS } from 'constants/analytics';

import { sendAnalyticDiscountAppliedResult, trackScreenLoadCancelOffer } from 'services/analytics';

import { changeSubscriptionErrorModalData, getChangeSubscriptionSuccessModalData } from 'helpers/subscriptions';

import { ModalName } from 'components/Modals/types';

import { CancelFlowOffers } from 'types/subscription';

import { pollGetSubscriptions } from './pollGetSubscriptions';

/**
 *
 * Can be applied only to active subscriptions. Calls the switch endpoint,
 * which changes the current subscription to a discounted one.
 */

export function* changeCurrentSubscription({ payload }: ReturnType<typeof updateSubscription>) {
    try {
        yield put(openModal(ModalName.WithLoaderModal, { title: 'subscription.waitingModal.updatingPlan.title' }));

        const { result }: IResultResponse = yield api.subscriptions.updateSubscription(payload);

        if (!result) {
            throw Error('changeCurrentSubscription returns false');
        }

        yield call(pollGetSubscriptions, checkAllSubscriptionsWithDiscount());

        yield call(trackScreenLoadCancelOffer, {
            screenId: CANCEL_OFFER_SUCCESS_SCREEN_ID,
            eventLabel: { subscription_id: payload.external_id, offer: CancelFlowOffers.Discount },
        });

        yield put(openModal(ModalName.SuccessModal, getChangeSubscriptionSuccessModalData(payload.product)));

        yield put(setDiscountSubscription(null));

        yield call(sendAnalyticDiscountAppliedResult, DISCOUNT_APPLIED_SUCCESS, payload.product.name);
    } catch (error) {
        yield put(
            openModal(ModalName.RetryErrorModal, {
                ...changeSubscriptionErrorModalData,
                retryAction: () => updateSubscription(payload),
            })
        );

        yield call(
            sendAnalyticDiscountAppliedResult,
            DISCOUNT_APPLIED_ERROR,
            'Subscription plan not updated due to a technical issue'
        );
    }
}
