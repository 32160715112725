import { SUBSCRIPTION_STATES } from 'constants/subscriptions';

import { ISubscription } from 'types/subscription';

export const checkSubscriptionsWithReminders = (externalIds: string[]) => (subscriptions: ISubscription[]) =>
    externalIds.every((externalId) =>
        subscriptions.some(
            (subscription) => subscription.external_id === externalId && subscription.is_charge_reminder_on
        )
    );

export const checkAllCancelledSubscriptions =
    (cancellationCandidates: ISubscription[]) => (subscriptions: ISubscription[]) =>
        cancellationCandidates.every((candidate) =>
            subscriptions.some(
                (subscription) =>
                    subscription.id === candidate.id &&
                    (!!subscription.cancelled_at || !!subscription.internal_cancel_at)
            )
        );

export const checkAllSubscriptionsWithDiscount = () => (subscriptions: ISubscription[]) =>
    subscriptions.some(({ discount_type }) => discount_type);

export const checkAllPausedSubscriptions = (externalIds: string[]) => (subscriptions: ISubscription[]) =>
    externalIds.every((externalId) =>
        subscriptions.some(
            (subscription) =>
                subscription.external_id === externalId && subscription.state === SUBSCRIPTION_STATES.Paused
        )
    );

export const checkAllResumedSubscriptions = (externalId: string) => (subscriptions: ISubscription[]) =>
    subscriptions.some(({ external_id, pause_to }) => external_id === externalId && pause_to === null);

export const checkSubscriptionRestored = (externalId: string) => (subscriptions: ISubscription[]) =>
    subscriptions.some(
        ({ external_id, internal_cancel_at, cancelled_at }) =>
            external_id === externalId && !internal_cancel_at && !cancelled_at
    );
