import { Button } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { selectCancellationCandidates } from 'store/subscriptions/selectors';

import { DISCOUNT_TYPES } from 'constants/subscriptions';
import { CANCEL_SUBSCRIPTION_SCREEN_ID } from 'constants/analytics';

import { trackScreenLoad } from 'services/analytics/trackers/mainTrackers';
import { sendAnalyticCancelOfferClick } from 'services/analytics';

import { useCancelSubscriptionFlow, useModals, useSelector } from 'hooks';
import { useGetTexts } from './hooks/useGetTexts';

import { getValuesByKey } from 'helpers/utils';
import { isMainSubscription } from 'helpers/subscriptions';

import OutlinedButton from 'components/OutlinedButton';
import BaseConfirmationModal from './components/BaseConfirmationModal';

import { CancelOfferAction } from 'types/subscription';

const ConfirmationModalWithDiscount = () => {
    const { t } = useTranslation();

    const { closeModal } = useModals();

    const { onCancelSubscriptionWithOffer, onCancelSubscription } = useCancelSubscriptionFlow();

    const cancellationCandidates = useSelector(selectCancellationCandidates);
    const subscription_id = getValuesByKey(cancellationCandidates, 'external_id');

    const isMultiSubs = cancellationCandidates && cancellationCandidates?.length > 1;

    const { title, subtitle } = useGetTexts({
        titleText: isMultiSubs
            ? 'subscription.confirmationModalWithDiscount.title.multi'
            : 'subscription.confirmationModalWithDiscount.title.single',
        subtitleText: isMultiSubs
            ? 'subscription.confirmationModalWithDiscount.subtitle.multi'
            : 'subscription.confirmationModalWithDiscount.subtitle.single',
    });

    useEffect(() => {
        if (subscription_id) {
            trackScreenLoad(CANCEL_SUBSCRIPTION_SCREEN_ID, {
                subscription_id,
            });
        }
    }, [subscription_id]);

    const onSubmit = () => {
        sendAnalyticCancelOfferClick({
            screenId: CANCEL_SUBSCRIPTION_SCREEN_ID,
            actionEvent: CancelOfferAction.Cancel,
            eventLabel: { subscription_id },
        });

        if (cancellationCandidates?.[0]) {
            const { product_code, discount_type } = cancellationCandidates[0];

            isMainSubscription(product_code) && discount_type !== DISCOUNT_TYPES.extra
                ? onCancelSubscriptionWithOffer()
                : onCancelSubscription();
        }
    };

    const onBackButtonClick = () => {
        sendAnalyticCancelOfferClick({
            screenId: CANCEL_SUBSCRIPTION_SCREEN_ID,
            actionEvent: CancelOfferAction.Close,
            eventLabel: { subscription_id },
        });

        closeModal();
    };

    return (
        <BaseConfirmationModal title={title} subtitle={subtitle} onBackButtonClick={onBackButtonClick}>
            <Button dataLocator="cancelBtn" onClick={onSubmit} text={t('basics.button.cancelSubscription')} mb={8} />
            <OutlinedButton
                dataLocator="goBackBtn"
                onClick={onBackButtonClick}
                text={t('basics.button.goBack')}
                backgroundColor="surface-main"
                mb={4}
            />
        </BaseConfirmationModal>
    );
};

export { ConfirmationModalWithDiscount };
