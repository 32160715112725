import { call, delay, put } from 'redux-saga/effects';

import { setSubscriptions } from '../actions';

import { ISubscription } from 'types/subscription';

import { getSubscription } from './getSubscription';

export function* pollGetSubscriptions(conditionCheck?: (data: ISubscription[]) => boolean) {
    let isConditionSatisfied = false;

    let result: ISubscription[] = [];

    while (!isConditionSatisfied) {
        result = yield call(getSubscription);

        isConditionSatisfied = !conditionCheck || !result || conditionCheck(result);

        if (!isConditionSatisfied) {
            // Wait for 2 seconds before polling again
            yield delay(2000);
        }
    }

    yield put(setSubscriptions(result ?? []));

    return result ?? [];
}
