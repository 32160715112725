import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import { ArrowAngleLeft, Cross } from 'components/Icon';
import RoundButton from 'components/Buttons/RoundButton';

import * as S from './styled';

import { FullScreenModalHeaderPropsType } from './types';

const FullScreenModalHeader = ({ onBackButtonClick, closeModal }: FullScreenModalHeaderPropsType) => {
    const { t } = useTranslation();

    return (
        <S.FullScreenModalHeader>
            <div>
                {onBackButtonClick && (
                    <RoundButton onClick={onBackButtonClick} data-locator="subscriptionModalBackButton">
                        <ArrowAngleLeft />
                    </RoundButton>
                )}
            </div>
            <Text
                medium
                color="text-main"
                type="large-text"
                text={t('subscription.discountOfferModal.headerTitle')}
                center
            />
            <RoundButton onClick={closeModal} data-locator="subscriptionModalCloseButton">
                <Cross />
            </RoundButton>
        </S.FullScreenModalHeader>
    );
};

export default FullScreenModalHeader;
