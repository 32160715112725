import { styled } from 'styled-components';

import { color } from 'theme/selectors';
import { mediaQueries } from 'theme/mediaQueries';

export const FullScreenModalHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid ${color('border-default')};
    background-color: ${color('surface-default')};

    :first-child {
        justify-self: start;
    }

    :last-child {
        justify-self: end;
    }

    ${mediaQueries.laptop} {
        padding-left: 32px;
        padding-right: 32px;
    }

    ${mediaQueries.desktop} {
        padding-left: 40px;
        padding-right: 40px;
    }
`;
