import { Box, Text } from 'wikr-core-components';

import { useModals } from 'hooks';

import HeaderWithLogo from 'components/ModalHeaders/HeaderWithLogo';
import Modal from 'components/Modal/Modal';

import { BaseConfirmationModalProps } from './types';

import { features } from './config';

import AppFeatures from '../AppFeatures/AppFeatures';

const BaseConfirmationModal = ({ onBackButtonClick, title, subtitle, children }: BaseConfirmationModalProps) => {
    const { closeModal } = useModals();

    return (
        <Modal
            isOpen
            onClose={closeModal}
            shouldCloseOnOverlayClick
            fullscreen
            withRoundedCorners={false}
            customStyles={{ padding: 0 }}
        >
            <HeaderWithLogo onBackButtonClick={onBackButtonClick} />
            <Box paddingTop={24} paddingBottom={16} data-locator="cancelSubModal" className="container-sm">
                <Text type="h5" text={title} mb={12} />
                <Text color="text-secondary" text={subtitle} mb={32} />

                <AppFeatures features={features} mb={40} />

                {children}
            </Box>
        </Modal>
    );
};

export default BaseConfirmationModal;
