import { call, put } from 'redux-saga/effects';

import api from 'api';

import { notifyError } from 'store/notifications/actions';
import { closeModal } from 'store/modals/actions';

import { ISubscription } from 'types/subscription';

export function* getSubscription() {
    try {
        const response: ISubscription[] = yield call(api.subscriptions.getSubscriptions);

        return response;
    } catch (error) {
        yield put(closeModal());
        yield put(notifyError({ message: 'basics.appError' }));
    }
}
