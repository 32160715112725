import { DISCOUNT_TYPES, SUBSCRIPTION_STATES } from 'constants/subscriptions';

import { CancellationReasonRetenoValue } from 'components/Modals/Subscriptions/CancellationReasonModal/types';

import { Product, SubscriptionProductCodes, IUpcomingProduct, OneTimeProductCodes } from './product';
import { Currencies } from './currency';

type UpsellProduct = {
    product_code: OneTimeProductCodes;
    id: string;
    price: number;
};

export interface ISubscription {
    cancelled_at: string | null;
    discount_granted_at: string | null;
    discount_type: DISCOUNT_TYPES | null;
    expired_date: string;
    external_id: string;
    id: number;
    internal_cancel_at: string | null;
    is_charge_reminder_on: boolean;
    pause_to: string | null;
    pause_type: SubscriptionPauseType;
    price: string;
    product: Product;
    product_code: SubscriptionProductCodes;
    purchase_date: string;
    state: SUBSCRIPTION_STATES;
    upcoming_product: IUpcomingProduct | null;
    upsells?: UpsellProduct[];
}

export interface ISubscriptionFeedback {
    reason: CancellationReasonRetenoValue;
}

export interface ICancelSubscriptionRequest {
    external_id: string;
    cancellation_reason: CancellationReasonRetenoValue;
}

export interface IFetchDiscountSubscription {
    discount_type: DISCOUNT_TYPES;
    external_id: string;
}

export interface IDiscountSubscription {
    id: string;
    name: string;
    amount: number;
    currency: Currencies;
    subscription_period: number;
    started_from: string;
    product_id: string;
}

export interface IUpdateSubscriptionData {
    product: IDiscountSubscription;
    external_id: string;
    discount_type: DISCOUNT_TYPES;
}

export interface IResetSubscription extends IUpdateSubscriptionData {
    expired_at: string;
}

export enum CancelOfferAction {
    Accept = 'accept',
    Cancel = 'cancel',
    Close = 'close',
    Explore = 'explore',
}

export enum SubscriptionPauseType {
    Pause = 'one_month_pause',
    FreeMonth = 'one_month_free',
}

export enum CancelFlowOffers {
    Discount = 'discount',
    Login = 'login',
    Reminder = 'reminder',
    DiscountAfterCancel = 'discount_after_cancel',
    FreeMonth = 'free_month',
    Pause = 'pause',
    ChangePlan = 'change_plan',
    Resume = 'resume',
}
