import { styled } from 'styled-components';
import CheckInCircle from 'assets/emoji/check_in_circle.svg?react';

import { borderRadius, color } from 'theme/selectors';

export const ReturnOfferContainer = styled.div`
    border-radius: ${borderRadius('medium')};
    background-color: ${color('success-surface')};
    padding: 12px;
    margin-bottom: 24px;
`;

export const ReturnOfferHeading = styled.div`
    margin-bottom: 8px;
    display: flex;
    column-gap: 9px;
`;

export const StyledIcon = styled(CheckInCircle)`
    path:first-of-type {
        fill: ${color('success-default')};
    }

    path:last-of-type {
        fill: ${color('decorative-c')};
    }
`;
