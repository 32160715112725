import { Currencies } from './currency';

export enum OneTimeProductCodes {
    Breathing = 'breathing',
    Meditation = 'meditation',
    NutritionDiet = 'nutrition_diet',
    NutritionFasting = 'nutrition_fasting',
    HabitBuilding = 'habit_building',
    Motivation = 'motivation',
    NutritionDietMeditationBreathing = 'nutrition_diet_meditation_breathing',
    MotivationMeditationBreathing = 'motivation_meditation_breathing',
    NutritionDietMeditationBreathingV2 = 'nutrition_diet_meditation_breathing_v2',
    HabitBuildingMeditationBreathing = 'habit_building_meditation_breathing',
    NutritionDietNutritionFasting = 'nutrition_diet_nutrition_fasting',
    MeditationBreathing = 'meditation_breathing',
    MotivationHabitBuilding = 'motivation_habit_building',
    NutritionDietHabitBuilding = 'nutrition_diet_habit_building',
    NutritionFastingHabitBuilding = 'nutrition_fasting_habit_building',
    MotivationMeditation = 'motivation_meditation',
}

export enum SubscriptionProductCodes {
    AppFullAccess = 'app_full_access',
    Main = 'main',
    face = 'face',
    Fatburn = 'fatburn',
    FatburnFace = 'fatburn_face',
    WorkoutBuilder = 'workout_builder',
    Highintense = 'highintense',
    Shoulders = 'shoulders',
    Pilates = 'pilates',
    Back = 'back',
    Weightloss = 'weightloss',
    Sevenintense = 'sevenintense',
    Hips = 'hips',
    Chair = 'chair',
    Wall = 'wall',
    VipSupport = 'vip_support',
    Sevenmin = 'sevenmin',
    Belly = 'belly',
    BellySofa = 'belly_sofa',
    Sofa = 'sofa',
    BellySevenmin = 'belly_sevenmin',
}

export interface Product {
    amount: number;
    currency: Currencies;
    id: string;
    name: string;
    payment_action: string;
    subscription_period: number;
    started_from: string;
}

export interface IUpcomingProduct {
    started_from: string;
    amount: number;
    currency: Currencies;
    subscription_period: number;
    id: string;
    name: string;
    product_id: string;
}
