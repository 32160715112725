import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import * as S from './styled';

const ReturnOfferMessage = () => {
    const { t } = useTranslation();

    return (
        <S.ReturnOfferContainer>
            <S.ReturnOfferHeading>
                <S.StyledIcon />
                <Text text={t('subscription.cancelledCard.info.title')} type="large-text" medium />
            </S.ReturnOfferHeading>
            <Text text={t('subscription.returnOfferMessage.body')} />
        </S.ReturnOfferContainer>
    );
};

export default ReturnOfferMessage;
