import { Button, Text } from 'wikr-core-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { restoreSubscription } from 'store/subscriptions/actions';

import { CURRENCY_SIGN_MAP } from 'constants/currency';
import { RESTORE_POPUP_ID } from 'constants/analytics';

import { trackScreenLoad } from 'services/analytics/trackers/mainTrackers';
import { sendAnalyticRestoreButtonClick } from 'services/analytics';

import { useModals } from 'hooks';

import { getPriceWithDecimals } from 'helpers/prices';
import { ButtonEventAction, CLOSE_EVENT_ACTION, RESTORE_EVENT_ACTION } from './helpers';

import OutlinedButton from 'components/OutlinedButton';
import { ModalName } from 'components/Modals/types';
import Modal from 'components/Modal/Modal';

import { ISubscription } from 'types/subscription';

const RestoreSubscriptionModal = () => {
    const { t } = useTranslation();

    const { openModal, closeModal, modalProps } = useModals();

    const dispatch = useDispatch();

    const { product, upcoming_product, external_id } = modalProps as ISubscription;

    const { currency, amount, subscription_period, name, id } = upcoming_product ?? product;

    useEffect(() => {
        trackScreenLoad(RESTORE_POPUP_ID);
    }, []);

    const sendAnalyticsClick = (eventAction: ButtonEventAction) => {
        sendAnalyticRestoreButtonClick(
            'restore_popup',
            {
                subscription_id: external_id,
                period: subscription_period,
                tariff: name,
                content_id: id,
            },
            eventAction
        );
    };

    const handleRestore = (eventAction: ButtonEventAction) => {
        sendAnalyticsClick(eventAction);
        dispatch(restoreSubscription(modalProps as ISubscription));
        openModal(ModalName.WithLoaderModal, { title: t('basics.loading'), img: null, subtitle: null });
    };

    const handleModalClose = (eventAction: ButtonEventAction) => {
        sendAnalyticsClick(eventAction);
        closeModal();
    };

    return (
        <Modal paddingY={16} isOpen onClose={closeModal} shouldCloseOnOverlayClick>
            <div data-locator="restoreSubModal">
                <Text type="h5" center text={t('subscription.restoreModal.title')} mb={12} />
                <Text
                    center
                    color="text-secondary"
                    text={t('subscription.restoreModal.subscriptionPeriod', {
                        period: subscription_period,
                        currencySign: CURRENCY_SIGN_MAP[currency],
                        price: getPriceWithDecimals({ price: amount, currency }),
                    })}
                    mb={24}
                />
                <Button
                    dataLocator="restoreBtn"
                    onClick={() => handleRestore(RESTORE_EVENT_ACTION)}
                    text={t('subscription.restoreModal.restoreButtonText')}
                    mb={20}
                />
                <OutlinedButton
                    dataLocator="cancelBtn"
                    onClick={() => handleModalClose(CLOSE_EVENT_ACTION)}
                    text={t('basics.cancel')}
                    mb={4}
                />
            </div>
        </Modal>
    );
};

export default RestoreSubscriptionModal;
