import isMobile from 'helpers/isMobile';

import Logo from 'components/Logo';
import { ArrowAngleLeft } from 'components/Icon';

import * as S from './styled';

import { HeaderWithLogoProps } from './types';

const HeaderWithLogo = ({ onBackButtonClick }: HeaderWithLogoProps) => (
    <S.HeaderWithLogo>
        <button onClick={onBackButtonClick} data-testid="modal-header-back-button">
            <ArrowAngleLeft size={20} />
        </button>
        <Logo withLogo={!isMobile.any()} />
    </S.HeaderWithLogo>
);

export default HeaderWithLogo;
