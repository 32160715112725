import { Button } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { selectCancellationCandidates } from 'store/subscriptions/selectors';

import { CANCEL_SUBSCRIPTION_SCREEN_ID } from 'constants/analytics';

import { trackScreenLoad } from 'services/analytics/trackers/mainTrackers';
import { sendAnalyticCancelOfferClick } from 'services/analytics';

import { useCancelSubscriptionFlow, useModals, useSelector } from 'hooks';
import { useGetTexts } from './hooks/useGetTexts';

import { getValuesByKey } from 'helpers/utils';

import OutlinedButton from 'components/OutlinedButton';
import { ModalName } from 'components/Modals/types';
import BaseConfirmationModal from './components/BaseConfirmationModal';

import { CancelOfferAction } from 'types/subscription';

const ConfirmationModal = () => {
    const { t } = useTranslation();

    const { openModal, closeModal } = useModals();

    const { onCancelSubscription } = useCancelSubscriptionFlow();

    const cancellationCandidates = useSelector(selectCancellationCandidates);
    const subscription_id = getValuesByKey(cancellationCandidates, 'external_id');

    const isMultiSubs = cancellationCandidates && cancellationCandidates?.length > 1;

    const { title, subtitle } = useGetTexts({
        titleText: isMultiSubs
            ? 'subscription.confirmationModal.title.multi'
            : 'subscription.confirmationModal.title.single',
        subtitleText: isMultiSubs
            ? 'subscription.confirmationModal.subtitle.multi'
            : 'subscription.confirmationModal.subtitle.single',
    });

    useEffect(() => {
        if (subscription_id) {
            trackScreenLoad(CANCEL_SUBSCRIPTION_SCREEN_ID, {
                subscription_id,
            });
        }
    }, [subscription_id]);

    const onSubmit = () => {
        sendAnalyticCancelOfferClick({
            screenId: CANCEL_SUBSCRIPTION_SCREEN_ID,
            actionEvent: CancelOfferAction.Explore,
            eventLabel: { subscription_id },
        });

        openModal(ModalName.CancellationReasonModal);
    };

    const onBackButtonClick = () => {
        sendAnalyticCancelOfferClick({
            screenId: CANCEL_SUBSCRIPTION_SCREEN_ID,
            actionEvent: CancelOfferAction.Close,
            eventLabel: { subscription_id },
        });

        closeModal();
    };

    const onCancel = () => {
        sendAnalyticCancelOfferClick({
            screenId: CANCEL_SUBSCRIPTION_SCREEN_ID,
            actionEvent: CancelOfferAction.Cancel,
            eventLabel: { subscription_id },
        });

        onCancelSubscription();
    };

    return (
        <BaseConfirmationModal title={title} subtitle={subtitle} onBackButtonClick={onBackButtonClick}>
            <Button
                dataLocator="exploreOptionsBtn"
                onClick={onSubmit}
                text={t('subscription.confirmationModal.exploreOptionsBtn')}
                mb={8}
            />
            <OutlinedButton
                dataLocator="cancelSubscriptionBtn"
                onClick={onCancel}
                text={t('basics.cancelBtn.cancelAnyway')}
                backgroundColor="surface-main"
                mb={4}
            />
        </BaseConfirmationModal>
    );
};

export default ConfirmationModal;
