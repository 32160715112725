import { call, put, take } from 'redux-saga/effects';

import { requestCancelSubscriptionWithOffer, setCancellationCandidates } from 'store/subscriptions/actions';
import { CLOSE_MODAL } from 'store/modals/actionTypes';
import { openModal } from 'store/modals/actions';

import { getCancelSubscriptionErrorModalData, getCancelSubscriptionSuccessModalData } from 'helpers/subscriptions';
import { checkAllCancelledSubscriptions } from '../helpers';

import { ModalName } from 'components/Modals/types';

import { ISubscription } from 'types/subscription';

import { pollGetSubscriptions } from './pollGetSubscriptions';
import { callUnsubscribe } from './callUnsubscribe';

export function* cancelSubscriptionWithOffer({ payload }: ReturnType<typeof requestCancelSubscriptionWithOffer>) {
    try {
        yield put(
            openModal(ModalName.WithLoaderModal, {
                title: 'subscription.withLoaderModal.cancellingPlan',
                img: null,
            })
        );

        yield call(callUnsubscribe, payload[0]);

        const { discount_type, external_id } = payload[0];

        const subscriptions: ISubscription[] = yield call(
            pollGetSubscriptions,
            checkAllCancelledSubscriptions(payload)
        );

        const cancellationCandidates = subscriptions.filter((subscription) => subscription.external_id === external_id);

        yield put(setCancellationCandidates(cancellationCandidates));

        yield put(openModal(ModalName.SuccessModal, { ...getCancelSubscriptionSuccessModalData(payload) }));

        if (!discount_type) {
            yield take(CLOSE_MODAL);
            yield put(
                openModal(ModalName.ReturnModal, {
                    isExtraOffer: false,
                })
            );
        }
    } catch (error) {
        yield put(
            openModal(ModalName.RetryErrorModal, {
                ...getCancelSubscriptionErrorModalData({
                    isSingleError: true,
                    subscriptionsCancellationFailedPartially: false,
                }),
                retryAction: () => requestCancelSubscriptionWithOffer(payload),
            })
        );
    }
}
