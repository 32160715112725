// import appConfig from 'appConfig';

import isMobile from './isMobile';

const tmpAndroidInstructionEN =
    'https://mobile.appscdn.io/!VIDEO/YogaApp/Public/Video/login_instructions_videos/YG_OnePass_ANDR_375x812.webp';
const tmpIOsInstructionEN =
    'https://mobile.appscdn.io/!VIDEO/YogaApp/Public/Video/login_instructions_videos/YG_OnePass_IOS_375x812.webp';

export const getVideoLink = () => {
    // This is a temporary solution until updating start screen on the mobile is implemented. Then we need to roll back the previous implementation
    return isMobile.android() ? tmpAndroidInstructionEN : tmpIOsInstructionEN;

    // const language = navigator.languages?.[1]?.toUpperCase() || 'EN';
    //
    // if (isMobile.android()) {
    //     // @ts-ignore
    //     return appConfig.video.videoLinks.android[language] || appConfig.video.videoLinks.android['EN'];
    // }
    //
    // // @ts-ignore
    // return appConfig.video.videoLinks.ios[language] || appConfig.video.videoLinks.ios['EN'];
};
