import { put } from 'redux-saga/effects';

import { openModal } from 'store/modals/actions';

import { getCancelSubscriptionSuccessModalData } from 'helpers/subscriptions';

import { ModalName } from 'components/Modals/types';

import { ISubscription } from 'types/subscription';

export function* callSuccessCancellingBehaviour({
    cancellationCandidates,
}: {
    cancellationCandidates: ISubscription[];
}) {
    const [firstCandidate, secondCandidate] = cancellationCandidates;

    yield put(
        openModal(ModalName.SuccessModal, {
            ...getCancelSubscriptionSuccessModalData([firstCandidate, secondCandidate]),
        })
    );
}
