import { Text } from 'wikr-core-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';

import { selectCancellationCandidates } from 'store/subscriptions/selectors';
import { setCancellationCandidates, setDiscountSubscription } from 'store/subscriptions/actions';

import { sendAnalyticCancelOfferClick, trackScreenLoadCancelOffer } from 'services/analytics';

import { useCancelSubscriptionFlow, useDiscountModal } from 'hooks/subscriptions';
import useModals from 'hooks/modals/useModals';
import { useSelector } from 'hooks';

import { getValuesByKey, roundToNearest } from 'helpers/utils';

import SubscriptionsSkeleton from 'components/SubscriptionsTab/components/SubscriptionsSkeleton';
import FullScreenModalHeader from 'components/ModalHeaders/FullScreenModalHeader';
import Modal from 'components/Modal';
import SubscriptionCards from '../components/SubscriptionCards';
import ReturnOfferMessage from '../components/ReturnOfferMessage';

import * as S from '../styled';

import { CancelFlowOffers, CancelOfferAction } from 'types/subscription';
import { ReturnOfferModalProps } from '../types';

const ReturnModal = ({ isExtraOffer = false }: ReturnOfferModalProps) => {
    const { closeModal } = useModals();
    const { t } = useTranslation();

    const { onChangeSubscription, getDiscountSubscription, onResetSubscription } = useCancelSubscriptionFlow();

    const dispatch = useDispatch();

    const cancellationCandidates = useSelector(selectCancellationCandidates);

    const { currentSubscription, currencySign, discountOffer } = useDiscountModal();

    const isDiscountOfferReady = discountOffer && currentSubscription && currencySign;

    const subscription_id = getValuesByKey(cancellationCandidates, 'external_id');

    useEffect(() => getDiscountSubscription(isExtraOffer), [getDiscountSubscription, isExtraOffer]);

    useEffect(() => {
        trackScreenLoadCancelOffer({ eventLabel: { subscription_id, offer: CancelFlowOffers.Discount } });
    }, [subscription_id]);

    useEffect(
        () => () => {
            dispatch(setDiscountSubscription(null));
        },
        [dispatch]
    );

    const sendAnalytics = useCallback(
        (action: CancelOfferAction) => {
            sendAnalyticCancelOfferClick({
                eventLabel: { subscription_id, offer: CancelFlowOffers.Discount },
                actionEvent: action,
            });
        },
        [subscription_id]
    );

    const changeSubscription = useCallback(() => {
        const { cancelled_at, internal_cancel_at } = cancellationCandidates?.[0] ?? {};

        sendAnalytics(CancelOfferAction.Accept);
        cancelled_at || internal_cancel_at ? onResetSubscription(isExtraOffer) : onChangeSubscription(isExtraOffer);
    }, [onChangeSubscription, sendAnalytics, isExtraOffer, cancellationCandidates, onResetSubscription]);

    const onClose = useCallback(() => {
        dispatch(setCancellationCandidates(null));
        sendAnalytics(CancelOfferAction.Close);
        closeModal();
    }, [closeModal, sendAnalytics, dispatch]);

    const discount = isDiscountOfferReady
        ? roundToNearest(Math.round(100 * (1 - Number(discountOffer.price) / Number(currentSubscription.price))))
        : null;

    return (
        <Modal
            fullscreen
            isOpen
            onClose={onClose}
            customStyles={{ background: '#F3F3F8', padding: 0 }}
            withRoundedCorners={false}
        >
            <FullScreenModalHeader onBackButtonClick={null} closeModal={onClose} />

            <div className="container-sm">
                <S.ModalContent>
                    {!isDiscountOfferReady ? (
                        <SubscriptionsSkeleton />
                    ) : (
                        <>
                            <div>
                                {!isExtraOffer && <ReturnOfferMessage />}
                                <Text
                                    medium
                                    center
                                    mb={12}
                                    type="h4"
                                    text={t('subscription.discountOfferModal.returnOffer.title', { percent: discount })}
                                    dataLocator="returnTitle"
                                />
                                <Text
                                    center
                                    type="large-text"
                                    text={t('subscription.discountOfferModal.returnOffer.description', {
                                        percent: discount,
                                    })}
                                    dataLocator="returnDescription"
                                />
                            </div>
                            <SubscriptionCards
                                currencySign={currencySign}
                                currentSubscription={currentSubscription}
                                onChangeSubscription={changeSubscription}
                                discountPrice={discountOffer.price}
                                discount={discount as number}
                                title={t('subscription.discountOfferModal.currentCard.canceledPlan.title')}
                            />
                        </>
                    )}
                </S.ModalContent>
            </div>
        </Modal>
    );
};

export { ReturnModal };
