import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectCancellationCandidates } from 'store/subscriptions/selectors';

import { getFormatedDate } from 'helpers/getFormatedDate';

type UseGetTextsProps = {
    titleText: string;
    subtitleText: string;
};

export const useGetTexts = ({ titleText, subtitleText }: UseGetTextsProps) => {
    const cancellationCandidates = useSelector(selectCancellationCandidates);
    const { t } = useTranslation();

    if (!cancellationCandidates?.length) return { title: '', subtitle: '' };

    const [firstCandidate, secondCandidate] = cancellationCandidates;
    const { product_code: firstCode, expired_date: firstDate } = firstCandidate;
    const { targetMonth: targetMonth1, targetDay: targetDay1, targetYear: targetYear1 } = getFormatedDate(firstDate);

    if (cancellationCandidates.length > 1) {
        const { product_code: secondCode, expired_date: secondDate } = secondCandidate;
        const {
            targetMonth: targetMonth2,
            targetDay: targetDay2,
            targetYear: targetYear2,
        } = getFormatedDate(secondDate);

        return {
            title: t(titleText, {
                firstCode,
                secondCode,
            }),
            subtitle: t(subtitleText, {
                firstCode,
                targetMonth1,
                targetDay1,
                targetYear1,
                secondCode,
                targetMonth2,
                targetDay2,
                targetYear2,
            }),
        };
    }

    return {
        title: t(titleText, {
            firstCode,
        }),
        subtitle: t(subtitleText, {
            firstCode,
            targetMonth1,
            targetDay1,
            targetYear1,
        }),
    };
};
