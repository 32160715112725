import { call, put } from 'redux-saga/effects';

import api from 'api';

import { getDeepLinkError, getDeepLinkSuccess } from 'store/deepLink/actions';

export function* getDeepLink() {
    try {
        const deepLink: { url: string } = yield call(api.user.deepLinks);

        yield put(getDeepLinkSuccess(deepLink.url));
    } catch (error: any) {
        yield put(getDeepLinkError(error));
    }
}
