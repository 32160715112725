import { all, call, put } from 'redux-saga/effects';

import api from 'api';

import { checkAllPausedSubscriptions } from 'store/subscriptions/helpers';
import { pauseSubscription } from 'store/subscriptions/actions';
import { openModal } from 'store/modals/actions';

import { CANCEL_OFFER_SUCCESS_SCREEN_ID } from 'constants/analytics';

import { trackScreenLoadCancelOffer } from 'services/analytics';

import {
    getFreeMonthSuccessModalData,
    getRequestPauseSubscriptionErrorModalData,
    getRequestPauseSubscriptionWaitingModalData,
    pauseSuccessModalData,
} from 'helpers/subscriptions';

import { ModalName } from 'components/Modals/types';

import { ISubscription, SubscriptionPauseType } from 'types/subscription';

import { pollGetSubscriptions } from './pollGetSubscriptions';

export function* requestPauseSubscription({ payload }: ReturnType<typeof pauseSubscription>) {
    try {
        yield put(
            openModal(ModalName.WithLoaderModal, getRequestPauseSubscriptionWaitingModalData(payload.pause_type))
        );

        yield all(
            payload.externalIds.map((external_id) =>
                call(api.subscriptions.pauseSubscription, { external_id, pause_type: payload.pause_type })
            )
        );

        const [firstSubscription]: ISubscription[] = yield call(
            pollGetSubscriptions,
            checkAllPausedSubscriptions(payload.externalIds)
        );

        const successModalInfo =
            payload.pause_type === SubscriptionPauseType.Pause
                ? pauseSuccessModalData
                : getFreeMonthSuccessModalData(firstSubscription.expired_date);

        if (firstSubscription) {
            yield call(trackScreenLoadCancelOffer, {
                screenId: CANCEL_OFFER_SUCCESS_SCREEN_ID,
                eventLabel: { subscription_id: payload.externalIds, offer: successModalInfo.offer },
            });

            yield all(
                payload.externalIds.map((external_id) =>
                    call(api.subscriptions.sendPauseStatus, { external_id, pause_type: payload.pause_type })
                )
            );

            yield put(openModal(ModalName.SuccessModal, successModalInfo));
        }
    } catch (error) {
        yield put(
            openModal(ModalName.RetryErrorModal, {
                ...getRequestPauseSubscriptionErrorModalData(payload.externalIds, payload.pause_type),
                retryAction: () => pauseSubscription(payload),
            })
        );
    }
}
