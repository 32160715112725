import { useTranslation } from 'react-i18next';

import { SubscriptionCardsProps } from '../types';

import SubscriptionCard from './SubscriptionCard';

const SubscriptionCards = ({
    currentSubscription,
    currencySign,
    discountPrice,
    onChangeSubscription,
    discount,
    title,
}: SubscriptionCardsProps) => {
    const { t } = useTranslation();

    const { period, price } = currentSubscription;

    return (
        <>
            <SubscriptionCard
                title={title}
                bgColor="text-secondary"
                price={price}
                period={period}
                currencySign={currencySign}
            />
            <SubscriptionCard
                title={t('subscription.discountOfferModal.discountCard.title', {
                    percent: discount,
                })}
                bgColor="critical-default"
                price={discountPrice}
                period={period}
                currencySign={currencySign}
                oldPrice={price}
                changeSubscription={onChangeSubscription}
                isDiscountCard
            />
        </>
    );
};

export default SubscriptionCards;
