import { call, select } from 'redux-saga/effects';

import { IResultResponse } from 'api/types/response';
import api from 'api';

import { selectCancelReason } from 'store/subscriptions/selectors';

import {
    sendAnalyticFrontCancelSubscriptionRequest,
    sendAnalyticSubscriptionCancelError,
    sendAnalyticSubscriptionCancelSuccess,
} from 'services/analytics';

import { CancellationReasonRetenoValue } from 'components/Modals/Subscriptions/CancellationReasonModal/types';

import { ISubscription } from 'types/subscription';

export function* callUnsubscribe(
    currentSubscription: ISubscription,
    successCount?: { current: number },
    errorCount?: { current: string[] }
) {
    try {
        const cancelReason: CancellationReasonRetenoValue = yield select(selectCancelReason);

        sendAnalyticFrontCancelSubscriptionRequest();

        const response: IResultResponse = yield call(api.subscriptions.unsubscribe, {
            external_id: currentSubscription.external_id,
            ...(cancelReason && { cancellation_reason: cancelReason }),
        });

        if (!response.result) {
            throw new Error('Subscription is not cancelled');
        }

        sendAnalyticSubscriptionCancelSuccess(currentSubscription.external_id);

        if (successCount) {
            successCount.current += 1;
        }
    } catch (error) {
        if (errorCount) {
            errorCount.current.push(currentSubscription.external_id);
        }

        sendAnalyticSubscriptionCancelError(currentSubscription.external_id);
    }
}
